import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

// Import the json data for language translation

import translateData from '../language/en.json'


export default function Login() {
    const {loginWithRedirect, isAuthenticated, logout} = useAuth0();

  return (
    <div className='login'>
        {!isAuthenticated && (
            <h5 onClick={() => loginWithRedirect({
              scope: 'openid profile email offline_access', // Include offline_access in the scope to get a refresh token
            })}>{translateData.login}</h5>
        )}
        {/* Log in, Prijava  */}
       {isAuthenticated && ( 
             <h5 onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
             {translateData.logout}</h5>
            //  Log out, Abmelden, Odjava
       )}

    </div>
  )
}
