// import logo from './logo.svg';
import './App.css';
// import logo from './logo/logo.png';
// const logo = require('./logo/logo.png');

import { useEffect, useState } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Updated import

import { useAuth0, user, getAccessTokenSilently } from '@auth0/auth0-react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React from 'react';


// import language json to change title
import translateData from './language/en.json'


import { StyledEngineProvider } from "@mui/material/styles";

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';




import RegisterProperty from './RegisterProperty';
import Menu from './Menu';
import { IoMdMenu } from "react-icons/io";

import Login from './components/Login';
import UserProfile from './components/UserProfile';
import PropertyBookings from './components/PropertyBookings';
import NotFound from './components/NotFound';
import axios from 'axios';

const logo = require('./logo/logo.png');



function App() {
  const {isAuthenticated, user, getAccessTokenSilently, loginWithRedirect} = useAuth0();
  
        const userMail = user ? user.email : null;


  const [isRegisterPropertyOpen, setIsRegisterPropertyOpen] = useState(false);
  const [isPropertyOpen, setIsPropertyOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for menu

  // useState for user status from db
  const [userStatus, setUserStatus] = useState('');

  const handleRegisterPropertyClick = () => {
    // if register property is opened, close property window
    setIsRegisterPropertyOpen(!isRegisterPropertyOpen);
    if(isOpen){
      setIsOpen(!isOpen);
    }
    if(isPropertyOpen){
      setIsPropertyOpen(!isPropertyOpen);
    }
  };


  // change the title name depending on the language

  useEffect(() => {
    document.title = translateData.tabTitle;
  }, []);

  // ...

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently(
        {
            ignoreCache: true,
            audience: `https://dev-u2xbt2hohxgza2ou.us.auth0.com/api/v2/`,
        }
      );
      try {
        const response = await axios.get(`https://homeowner.22estates.com/api/api/getUser/${userMail}`, {
          headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
        });
        setUserStatus(response.data);
        // Process the response data here
      } catch (error) {
        console.error(error);
        // Handle the error here
      }
    };

    if(isAuthenticated){
      fetchData();

    }

    
  }, [userMail]);

  



  const handlePropertyClick = () => {
    // if property window is opened, close register property window
    setIsPropertyOpen(!isPropertyOpen);
    // If menu is open and a menu item is clicked, close menu
    if(isOpen){
      setIsOpen(!isOpen);
    }
    if(isRegisterPropertyOpen){
      setIsRegisterPropertyOpen(!isRegisterPropertyOpen);
    }
  }
 

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu state
  };

  // For the menu drawer

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  const menuItems = [
    { text: 'Register Property', onClick: () => handleRegisterPropertyClick(), role: "admin" 
    },
    { text: 'View Reservations', onClick: () => handlePropertyClick(), role: "user" },
  ];

  const list = () => (
    <List>
      {menuItems.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemButton onClick={item.onClick}>
        {/* compare the role from the database with the predefined role */}
            {(userStatus.role == item.role) && (<ListItemText primary={item.text} />)}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );


  


  return (
    // <StyledEngineProvider injectFirst>
    <Router>
    
     <div className="App">
      {/* <div className="header"> */}
        {/* <h5>22 Estates </h5> */}
        
      {/* </div> */}
      <div className="App-header">
        {/* <button className='sandwichMenu' onClick={handleMenuClick}>
          {!isMenuOpen && <IoMdMenu />}
          </button>  */}
          {/* Add menu button */}
        {/* {isMenuOpen  && ( // Render menu if it's open
            <Menu menuStatus={isMenuOpen} handleMenuClick={handleMenuClick} 
            handleRegisterPropertyClick={handleRegisterPropertyClick}/>
        )} */}
        
  

    {/* Menu drawer */}
    
          <>
          <AppBar position="fixed" sx={{ zIndex: 100, }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "white" }}>
          { (isAuthenticated && false ) &&
          (<IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer(!isOpen)}
            sx={{ mr: 2, position: "absolute", left: 15}}
          >
            <MenuIcon style={{zIndex: 160}}/>
          </IconButton>)}
          
          {/* Place other AppBar content here */}
          <img className='logo' src={logo}/>
        <span className='user-commands'>
          <Login/>
          {user && (
            // <img style={{width: "3vw", marginLeft: "5px", cursor: "auto"}} src={`${user.picture}`} />
            <img className='iconUser' src={`${user.picture}`} />
          )}
        </span>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor='left'
        open={isOpen}
        onClose={toggleDrawer(false)}       
      >
        {list()}
      </Drawer>
          </>
          

    {/* End of Menu drawer */}
          <Routes>
          <Route path="/" element={
            <div className="App-header">
                  { (isAuthenticated && userStatus.role == 'user') && (<PropertyBookings userMail={userMail}/>)}
                  

                  {/* { (isRegisterPropertyOpen && isAuthenticated) && ( */}
                  { (isAuthenticated && userStatus.role == 'admin') && (
                    <RegisterProperty onClose={handleRegisterPropertyClick} />
                  )}
                
                  
                      {/* If user is not logged in, display a message */}
                
                  {!isAuthenticated && 
                  (<div className='user-logged-out'>
                      {/* <p>Please log in to continue.</p> */}
                      
                      <p style={{
                        textDecoration: 'underline', /* Underlines the text to mimic a hyperlink */
                        cursor: 'pointer',
                      }} onClick={() => loginWithRedirect({
                        scope: 'openid profile email offline_access', // Include offline_access in the scope to get a refresh token
                      })}>{translateData.logInSentence}</p>
                      {/* <p>Bitte einloggen zum Fortfahren.</p> */}
                      {/* <p>Log in to continue.</p> */}
                      
                  </div>
                  )}
          </div>
          } /> {/* Updated */}
          <Route path="*" element={<NotFound />} /> {/* Updated */}

          </Routes>
      </div>
    </div>
    </Router>
    // </StyledEngineProvider>
  );
}

export default App;
