import React from 'react';
import moment from 'moment';
import 'moment/locale/de'; // Import Croatian locale

import { Grid, Paper, Typography, Box } from '@mui/material';
import translateData from '../language/en.json'



// Set the locale globally to Croatian
moment.locale('de');


const CalendarComponent = ({ bookings }) => {

  // create the object that stores the booking dates 
  // this way it is easier and more intuitive to access the data
  const bookingDates = bookings.map(booking => ({
    start: moment(booking.Arrival),
    end: moment(booking.Departure),
    name: booking.Name
  }));

  const getColor = (date, bookingDates) => {
    let isArrival = false;
    let isDeparture = false;
    let isBooked = false;
    let isOwnBooking = false;

    for (let booking of bookingDates) {
      if (booking.name === 'Eigene Buchung') {
        if (date.isSame(booking.start, 'day') || date.isSame(booking.end, 'day') || date.isBetween(booking.start, booking.end, 'day', '[]')) {
          isOwnBooking = true;
        }
      }
      if (date.isSame(booking.start, 'day')) {
        isArrival = true;
      }
      if (date.isSame(booking.end, 'day')) {
        isDeparture = true;
      }
      if (date.isBetween(booking.start, booking.end, 'day', '[]')) {
        isBooked = true;
      }
    }


    if (isOwnBooking) {
      if(isDeparture && isArrival) {
        return { background: '#9e9e9e', diagonal: 'departure' }; // Own booking - gray background
      }
      else if(isArrival) {
        return { background: '#9e9e9e', diagonal: 'arrival' }; // Own booking - green background when the arrival is not booked
      }
      else if (isDeparture) {
        return { background: '#9e9e9e', diagonal: 'departureOwn' }; // Own booking - gray background when the departure is in the category of own booking
      }
      return { background: '#9e9e9e', diagonal: 'none' }; // Own booking - gray background for both days
      
    }
    else if (isArrival && isDeparture) {
      return { background: '#E45F75', diagonal: 'both' }; // Both arrival and departure on the same day
    } else if (isArrival) {
      return { background: '#E45F75', diagonal: 'arrival' }; // Arrival is booked
    } 
    else if (isDeparture) {     
      if(isBooked) {
        return { background: '#66bb6a', diagonal: 'arrivalLast' }; // Departure when the booking is ending and there is no new booking
      }
      return { background: '#E45F75', diagonal: 'departure' }; // Departure
    } else if (isBooked) {
      
      return { background: '#E45F75', diagonal: 'both' }; // Fully booked
    }

    return { background: '#66bb6a', diagonal: 'none' }; // Green for non-booked dates
  };

  const renderMonth = (month, year) => {
    // render the start date, get the current year and month
    const start = moment([year, month]);
    const end = start.clone().endOf('month');
    const daysInMonth = [];
    // fill the daysInMonth object with number of days
    for (let date = start.clone(); date.isBefore(end) || date.isSame(end, 'day'); date.add(1, 'day')) {
      daysInMonth.push(date.clone());
    }

    // Adjust first day of week to Monday (European standard)
    const firstDayOfWeek = (start.day() + 6) % 7;
    const emptyDaysBefore = Array.from({ length: firstDayOfWeek }).map((_, index) => (
      <Box key={`empty-start-${index}`} width="40px" height="40px" margin="1px" bgcolor="#f0f0f0" />
    ));
    const filledDays = daysInMonth.map(day => {
      const { background, diagonal } = getColor(day, bookingDates);

      return (
        <Box
          key={day.toString()}
          width="40px"
          height="40px"
          position="relative"
          bgcolor={background}
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin="1px"
          // the sx sets the style of date boxes according to the boolean values in the function getColor
          sx={{
            borderRadius: '4px',
            color: '#fff',
            transition: 'transform 0.2s',
            cursor: 'pointer',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: diagonal === 'arrival' ? 'linear-gradient(135deg, #66bb6a 50%, transparent 50%)' : 
              (diagonal === 'both' ? 'linear-gradient(135deg, #E45F75 50%, transparent 50%)' : 
              (diagonal === 'arrivalLast' ? 'linear-gradient(135deg, #E45F75 50%, transparent 50%)' : 'none')),
              zIndex: 1
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: diagonal === 'departure' ? 'linear-gradient(135deg, transparent 50%, #E45F75 50%)' : 
              (diagonal === 'both' ? 'linear-gradient(135deg, transparent 50%, #E45F75 50%)' : 
              (diagonal==='departureOwn' ? 'linear-gradient(135deg, transparent 50%, #66bb6a 50%)' :'none')),
              zIndex: 2
            },
            '&:hover': {
              transform: 'scale(1.05)'
            }
          }}
        >
          <Typography variant="body2" style={{ fontWeight: 'bold', zIndex: 3 }}>
            {day.format('D')}
          </Typography>
        </Box>
      );
    });

    const totalBoxes = emptyDaysBefore.length + filledDays.length;
    const emptyDaysAfter = Array.from({ length: (7 - (totalBoxes % 7)) % 7 }).map((_, index) => (
      <Box key={`empty-end-${index}`} width="40px" height="40px" margin="1px" bgcolor="#f0f0f0" />
    ));


    // Function to capitalize the first letter of a string
    // added to fix an error that I had with croatian locale
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
      <Paper elevation={15} style={{ padding: '10px', margin: '10px', width: '320px', borderRadius: '8px', backgroundColor: '#fff' }}>
        <Typography variant="h6" align="center" style={{ marginBottom: '10px', color: '#333', fontWeight: 'bold' }}>
          {/* For Croatian locale, use the capitalizeFirstLetter because the locale does not work correctly */}
          {/* {capitalizeFirstLetter(start.format(`MMMM (M) YYYY`))}  */}
          {capitalizeFirstLetter(start.format(`MMMM YYYY`))} 
        </Typography>
        <Box display="flex" justifyContent="center" width="100%" mb={1}>
          {/* The part that maps the days of the week */}
          {translateData.days.map(day => (
            <Typography key={day} variant="body2" style={{ width: '42px', textAlign: 'center', fontWeight: 'bold', color: '#555' }}>
              {day}
            </Typography>
          ))}
        </Box>
        <Box display="flex" flexWrap="wrap" justifyContent="center" width="100%">
          {emptyDaysBefore}
          {filledDays}
          {emptyDaysAfter}
        </Box>
      </Paper>
    );
  };

  return (
    <Grid container justifyContent="center" columnSpacing={15} style={{}}>
      {/* The part that maps the months of the year. 
      The months are available in the language pack */}
      {translateData.months.map((month, index) => (
        <Grid item key={month} xs={12} sm={6} md={4} style={{display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap"}}>
          {renderMonth(index + 4, 2024)} {/* Adding 4 because May is the 5th month (index 4) */}
        </Grid>
      ))}
    </Grid>
  );
};

export default CalendarComponent;
