import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropertyTable from './PropertyTable';

import { useAuth0 } from '@auth0/auth0-react';

// Import the json data for language translation

import translateData from '../language/en.json'


function PropertyBookings({ userMail }) {
    const [bookings, setBookings] = useState({});
    // const [accessToken, setAccessToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    



    useEffect(async () => {

        const accessToken = await getAccessTokenSilently(
            {
                ignoreCache: true,
                audience: `https://dev-u2xbt2hohxgza2ou.us.auth0.com/api/v2/`,
            }
          );
        
        
        async function fetchUserID() {
            
            try {
                // console.log(userMail)
                const response = await axios.get(`https://homeowner.22estates.com/api/api/getUser/${userMail}`, {
                // }).then((response) => {
                // const response = await axios.get(`http://localhost:3001/api/getUser/${userMail}`, {
                    headers: {
                         Authorization: `Bearer ${accessToken}`,
                    }
                }).then((response) => {
                    // you can get your data here.
                    // userID, email etc.
                    // console.log(response.data)
                    fetchBookings(response.data.id);

                    // return response.data
                });

            } catch (error) {
                console.log(error)
            }
        }
        async function fetchBookings(userID) {
            try {
                // console.log(userID)
                const response = await axios.get(`https://homeowner.22estates.com/api/api/bookings?userID=${userID}`, { 
                // const response = await axios.get(`http://localhost:3001/api/bookings?userID=${userID}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                }); // Adjust the URL to your API endpoint
                const bookingsByProperty = response.data.reduce((acc, booking) => {
                    const { propertyName } = booking;
                    if (!acc[propertyName]) {
                        acc[propertyName] = [];
                    }
                    acc[propertyName].push(booking);
                    return acc;
                }, {});
                
                setBookings(bookingsByProperty);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        }

        
        fetchUserID();

        const interval = setInterval(() => {
            fetchUserID();
            // fetchBookings(userID);
        }, 5000);

    }, []);

    

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;


    return (
        <div style={{paddingTop: "55px"}}>
            <h1>{translateData.mainTitle}</h1>
            {/* Prikaz rezervacija */}
            {/* Buchungen */}
            <p style={{padding: "5px"}}>{translateData.mainHeader}</p>
           
            {/* Prikazan je djelomični prikaz rezervacija, za više rezervacija pomaknite prikaz prema dolje. */}
           {/* Die Buchungen sind nur teilweise sichtbar, für weitere bitte nach unten scrollen. */}

            <PropertyTable bookings={bookings} />
        </div>
    );
}

export default PropertyBookings;
